<template>
  <div class="main-container">
    <div class="banner">
      <img src="./../assets/logo-light.png" alt="logo home" />
      <h1>Contactez-nous</h1>
    </div> 
    <section class="contact-section">
      <div class="contact-row">
        <div class="contact-col">
          <div class="contact-item">
            <div class="contact-name">FACIL'IMMO</div>
            88, Rue Edmond Rostand<br />
            13 006 Marseille<br />
            France
          </div>
        </div>
        <div class="contact-col">
            <ContactForm
              type="contact"
            ></ContactForm>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm from './../components/ContactForm.vue'

export default {
  name: 'Contact',
  components : {
    ContactForm
  }

}
</script>

<style lang="scss" scoped>
  .banner {
    background-image: url('./../assets/banner-home.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  h1 {
    text-align: center;
    color:#fff;
    font-size:40px;
    font-weight:500;
    margin:0;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top:20px;
  }

  .banner  img {
    width:150px;
  }

  .contact-section {
    padding:80px 0;
  }

  .contact-row {
    width:100%;
    display: flex;
    align-items: center;
  }

  .contact-col:nth-child(1) {
    width:40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .contact-col:nth-child(2) {
    width:60%;
  }

  .contact-item {
    color:$text-color;
    font-weight: 400;
    font-size:18px;
    margin:20px 0 20px 20px;
    width: 300px;
  }

  .contact-name {
    font-weight: 500;
    font-size:22px;
  }

  @media screen and (max-width:768px) {
    
    .contact-row  {
      flex-direction: column;
    }

    .contact-col:nth-child(1), .contact-col:nth-child(2) {
      width:100%
    }

    .contact-col:nth-child(1) {
      align-items: flex-start;
    }

    .contact-item {
      margin: 20px auto;
      width:90%;
      text-align: center;
    }

    h1 {
      font-size:30px;
    }
  }
</style>